@tailwind base;
@tailwind components;
@tailwind utilities;
/* :root, */
:root {
    --bg-color: #fff;
    --text-color: #123;
    --bright-color: #f97b29;
    --border-input: #ded7d7;
    --background-input: #dddddd;
    --project-border-color: #cfcfcf;
}

:root.dark {
    --bg-color: #121212;
    --text-color: #fff;
    --bright-color: #f59d63;
    --border-input: #374151;
    --background-input: #373e48;
    --project-border-color: #f5f2f2;
}

body {
    margin: 0;
    color: var(--text-color);
    background-color: var(--bg-color);
    transition: all 0.5s ease;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

nav .navlist .navlink,
nav .navlist .navlink:after,
nav .navlist .navlink:before {
    transition: all 0.5s;
}
nav .navlist .navlink:hover,
nav .navlist button:hover {
    color: var(--bright-color);
}
nav .navlist .active {
    color: var(--bright-color);
}
/* stroke */
nav .navlist .navlink {
    position: relative;
}
nav .navlist .navlink:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: var(--bright-color);
    height: 1px;
}
nav .navlist .active:after {
    color: transparent;
    background: transparent;
}
nav .navlist .navlink:hover:after {
    width: 100%;
}

#toggle {
    width: 50px;
    display: flex;
    padding: 5px;
    background-color: #1a202c;
    display: block;
    border-radius: 1000px;
    cursor: pointer;
    box-shadow: 0px 5px 20px -10px #000000;
    transition: background-color 0.2s ease-in;
}

#toggle .toggle-inner {
    width: 20px;
    height: 15px;
    background-color: white;
    border-radius: 1000px;
    transition: margin-left 0.2s ease-in, background-color 0.2s ease-in;
}

#toggle .toggle-inner.toggle-active {
    margin-left: 20px;
}

.theme-btn.dark {
    display: none;
}

.light .theme-btn.dark {
    display: block;
}

.light .theme-btn.light {
    display: none;
}
.light .adaptive-color {
    color: white;
}
.colored-text {
    color: var(--bright-color);
}
.colored-background {
    background-color: var(--bright-color);
}
.project-image-container {
    position: relative;
    width: 50%;
    border: 2px solid var(--project-border-color);
}

.project-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.link-above-image {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.project-image-container:hover .project-image {
    opacity: 0.3;
}

.project-image-container:hover .link-above-image {
    opacity: 1;
}

.text {
    background-color: var(--bright-color);
    color: white;
    /* font-size: 16px;
  padding: 16px 32px; */
}
.hbtn {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    padding: 8px 20px;
    margin: 0px 3px 6px;
    text-align: center;
    border: 2px solid var(--bright-color);
    text-decoration: none;
    color: var(--bright-color);
    white-space: nowrap;
    z-index: 0;
}
.page-header {
    color: var(--bright-color);
}
.hbtn {
    position: relative;
    transition-duration: 0.3s;
    overflow: visible;
    box-sizing: border-box;
    border: none;
    padding: 10px 22px;
}

.hbtn::after {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border: 2px solid var(--bright-color);
    z-index: 2;
    margin: 0px;
    left: 0px;
    bottom: 0px;
    border-top-width: 2px;
    transition: border-top-width 0.1s ease 0.2s, height 0.2s ease 0.1s,
        width 0.2s ease 0s, margin 0.2s ease 0s,
        border-bottom-width 0.2s ease 0s;
}

.hbtn:hover::after {
    width: 60%;
    height: 0px;
    border-width: 0px 2px 4px;
    margin: 0px 20%;
    transition: border-top-width 0.1s ease 0s, height 0.2s ease 0.1s,
        width 0.2s ease 0.2s, margin 0.2s ease 0.2s,
        border-bottom-width 0.2s ease 0.2s;
}
.active-mode-btn {
    fill: var(--bright-color);
}
.feedback-input {
    background-color: var(--background-input);
    border: var(--border-input);
    color: var(--text-color);
}
